import BuildPageCard from './BuildPageCard.vue';
import {reactive, toRefs, onBeforeMount, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
import BuildPageListUtil ,{IBuildPageListDataObj} from "./buildPageListUtil";
import language from './buildPageLanguage'
export default defineComponent ({
    name: "buildPageList",
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IBuildPageListDataObj=reactive<IBuildPageListDataObj>({
            utilInst:{} as any,

            refMap:new Map(),
            pageListRef:null,

            pageList: {
                queryParam: {},
                modelComp: BuildPageCard,
                modelMethod: utils.Api.buildUrl("/buildPage/pageData")
            },
            otherParams:{

            }
        })


        onBeforeMount(()=>{
            dataObj.utilInst=new BuildPageListUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
        })

        return{
            ...toRefs(dataObj)

        }
    },
    components: {}
});